<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { cookieNames, setCookie, getCookie } = useCookies()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const route = useRoute()
const searchStore = useSearchStore()
const limit = ref(true)
const recentSearches = ref<string[]>([])
const isLoading = ref(true)
const limitedRecentSearches = computed(() =>
  recentSearches.value.slice(0, limit.value ? 10 : recentSearches.value.length)
)

// 쿠키에서 최근 검색어 추출하고 모델화
watch(
  () => route.query,
  async (cur) => {
    if (typeof document !== 'undefined' && cur.keyword) {
      let searches = getCookie<string[]>(cookieNames.SEARCHES) || []
      searches = searches.filter((item) => item !== cur.keyword)
      searches = [cur.keyword as string].concat(searches)

      await nextTick()
      recentSearches.value = searches
      setCookie(cookieNames.SEARCHES, searches, 30, 'days')
    }
  },
  { immediate: true }
)

// 스토어 키워드 검색어 변경되면 쿠키 세팅하고 모델화
watch(
  () => searchStore.keyword,
  async (cur) => {
    const searches = getCookie<string[]>('searches') || []
    const replaceTargetIdx = searches.indexOf(cur)

    if (replaceTargetIdx !== -1) {
      searches[replaceTargetIdx] = cur
    } else {
      searches.unshift(cur)
    }

    await nextTick()
    recentSearches.value = searches
    setCookie(cookieNames.SEARCHES, searches, 30, 'days')
  }
)

// 페이지 준비 끝나면 로딩 인디케이터 비 노출
nuxtApp.hook('page:finish', () => {
  isLoading.value = false
})

// 검색어를 기준으로 검색(refresh)
const handleOnSearch = async (index: number) => {
  const keyword = recentSearches.value[index]
  searchStore.updateKeyword(keyword)
  await useNavigations({ url: route.path, query: { keyword } })
  setTimeout(() => {
    window.scrollTo({ top: 0 })
  })
}

// 최금 검색어 삭제
const handleOnRemove = (index: number) => {
  recentSearches.value.splice(index, 1)
  setCookie(cookieNames.SEARCHES, recentSearches.value, 30, 'days')
}

// 검색어 제한 해제
const handleOnUnLimit = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '검색 결과 > 최근 검색어 > 전체보기',
    eventLabel: t('allView'),
    eventSlot: '',
    eventI18nAddr: useKoreanTranslation('allView'),
    eventComponent: 'Button',
  })
  limit.value = false
}
</script>

<template>
  <NovaBoxBase class="recent-searches">
    <h4 class="box-title">{{ $t('recentSearches') }}</h4>

    <div
      :class="['search-items', { 'has-action': recentSearches.length >= 10 }]"
    >
      <NovaLoadingIndicator v-if="isLoading" :bg-bright="'light'" />

      <div v-if="!isLoading" class="inner">
        <NovaBoxRecentSearchesItem
          v-for="(item, index) in limitedRecentSearches"
          :key="index"
          :keyword="item"
          :on="item === searchStore.keyword"
          @on-search="handleOnSearch(index)"
          @on-remove="handleOnRemove(index)"
        />
      </div>

      <NovaBoxEmptyMessage
        v-if="!isLoading && !recentSearches.length"
        :message="$t('statusMessages.recentSearches.empty')"
      />
    </div>

    <div
      v-if="recentSearches.length >= 10 && limit"
      class="actions"
      @click="handleOnUnLimit"
    >
      <button type="button" class="btn-go-searches">
        <span class="label">{{ $t('allView') }}</span>
        <NovaIcon
          :icon="{ type: 'outline', name: 'arrow-right' }"
          :size="20"
          class="icon"
        />
      </button>
    </div>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.recent-searches {
  padding: 0 !important;
  max-height: 100%;

  .box-title {
    flex-shrink: 0;
    padding: 20px 20px 15px;
    @include text-style($text-display-bold);
  }

  .search-items {
    padding: 0 20px 20px;
    overflow-y: overlay;

    &.has-action {
      padding-bottom: 15px;
    }

    > .inner {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .actions {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 20px;

    .btn-go-searches {
      display: flex;
      align-items: center;
      gap: 2px;
      color: $color-text-3;

      > .label {
        @include text-style($text-body-12-regular);
      }
    }
  }
}
</style>
